import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import BackgroundImage from 'gatsby-background-image';
import { Title } from './Typography';
import { ShopNow } from './styles';

const BackgroundImageComponent = (data) => {
  const { img, title, className, indexHero, location, titleWidth } = data;
    return (
      <BackgroundImage className={className} fluid={img}>
        <Title indexHero={!!indexHero} width={titleWidth}>
          <ReactMarkdown className="main_title">
            {title}
          </ReactMarkdown>
          {location === '/' ? (
          <ShopNow>
            <a href="https://stanfordsonoma.com/services/custom-fabrication" target="__blank">Shop Now</a>
          </ShopNow>
          ) : ""}
        </Title>
      </BackgroundImage>
    );
};

export const StyledBackground = styled(BackgroundImageComponent)`
  height: 25vh; //height: calc(55vh - 90px);
  ${(props) =>
    props.indexHero &&
    `
     height: 50vh;
  `};

  @media (min-width: 960px) {
    height: 270px;
    ${(props) =>
      props.indexHero &&
      `
     height: 55vh;
  `}
  }
`;

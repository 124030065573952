import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import HeadHelmet from "../components/head"
import { StyledBackground } from '../components/ui-elements/backgroundImage';
import { HelperDiv } from '../components/ui-elements/Typography';
import ContactDiv from "../components/contactUs"
import ProcessFlowMobile from "../components/processFlowMobile"
import ProcessFlowDesktop from "../components/processFlowDesktop"
import useWindowSize from "../hooks/useWindowSize"

const ServicesPage = ({ data }) => {
  const { strapiSsServices } = data

  const [active, setActive] = useState(1)
  const { width } = useWindowSize()

  const isMobile = width <= 1023

  const renderTabs = (data) => {
    const tabArray = []
    data.forEach((node) => {
      tabArray.push(
        <span className={"Services__tabs"} key={node.id}>
          {node.title}
        </span>
      )
    })
    return tabArray
  }

  const renderTabContent = (active) => {
    const activeTabContent = strapiSsServices.ssProcess.find(
      (node) => node.id === active
    )
    if (!isMobile) {
      return (
        <div>
          <Img
            className={"Services__container--servicesProcessIconsImageHelper"}
            fluid={activeTabContent.image.childImageSharp.fluid}
          />
          <p className={"SharedEl__textContent--center"}>
            {activeTabContent.content}
          </p>
        </div>
      )
    } else {
      return ""
    }
  }

  return (
    <Layout>
      <HeadHelmet data={strapiSsServices.MetaData} />
      <StyledBackground img={strapiSsServices.hero_image_services.childImageSharp.fluid} title={strapiSsServices.title}/>
        <div className={"SharedEl__textContent--center"}>
          <HelperDiv>
          <ReactMarkdown>{strapiSsServices.section_1_content}</ReactMarkdown>
          </HelperDiv>
          <Link className={"Services__blueButton"} to="/our-company/contact">
            Contact Us
          </Link>
        </div>
        <div className={"SharedEl__container--greyBG"}>
          <div className={"Wood__desktopGrid"}>
            <div className={"SharedEl__container"}>
              <div
                className={
                  "SharedEl__textContent--center SharedEl__textContent--leftNormalMargin SharedEl__listStyle"
                }
              >
                <div>
                  <div className={"SharedEl__headingsWidth"}>
                    <h3 className={"SharedEl__underline"}>
                      {strapiSsServices.section_2_title}
                    </h3>
                  </div>
                  <div className={"SharedEl__ulHelper"}>
                    <ReactMarkdown className={"Wood__listHelper"}>
                      {strapiSsServices.section_2_content}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Img
                className={"Services__container--servicesSecondImageHelper"}
                fluid={strapiSsServices.section_2_image.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
        <div className={"Services__centerDiv"}>
          {isMobile ? (
            <ProcessFlowMobile
              data={strapiSsServices.ssProcess}
            />
          ) : (
            <ProcessFlowDesktop
              active={active}
              setActive={setActive}
              onChange={(active) => setActive(active)}
              data={strapiSsServices.ssProcess}
            >
              {renderTabs(strapiSsServices.ssProcess)}
            </ProcessFlowDesktop>
          )}
        </div>

        {renderTabContent(active)}

        <div className={"SharedEl__container--greyBG"}>
          <div
            className={
              "SharedEl__textContent--left SharedEl__listStyle Services__centerList"
            }
          >
            <h3 className={"SharedEl__underline"}>
              {strapiSsServices.section_3_title}
            </h3>
            <div className={"Services__listHelper"}>
              <ReactMarkdown>
                {strapiSsServices.section_3_content}
              </ReactMarkdown>
            </div>
          </div>
        </div>
        <ContactDiv />
    </Layout>
  )
}

export default ServicesPage

export const pageQuery = graphql`
  query {
    strapiSsServices {
      id
      MetaData {
        id
        title
        metaDescription
        metaKeywords
      }
      title
      hero_image_services {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_1_content
      section_2_title
      section_2_content
      section_2_image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ssProcess {
        id
        title
        content
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      section_3_title
      section_3_content
    }
  }
`

import React from "react"
import Img from "gatsby-image"

const ProcessFlowMobile = ({ data }) => {
  return (
    <div className={"SharedEl__textContent--center"}>
      <h3 className={"SharedEl__underline"}>Process</h3>
      <div className={"Services__processDisplay"}>
        {data.map((item, i) => {
          return (
            <div key={item.id}>
              <h4 className={"Services__greyText"}>{item.title}</h4>
              <Img
                className={
                  "Services__container--servicesProcessIconsImageHelper"
                }
                fluid={item.image.childImageSharp.fluid}
              />
              <p>{item.content}</p>
              <hr className={"Services__hrLine"} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProcessFlowMobile

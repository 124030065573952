import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import phoneValidator from "phone"

export default () => (
  <StaticQuery
    query={graphql`
      query contactQuery {
        strapiSsContactPage {
          id
          info_form_phone
        }
      }
    `}
    render={(data) => {
      const { strapiSsContactPage } = data

      return (
        <div className={"row"}>
          <div className={"ContactDiv__contactDivStyle"}>
            <h2>
              Contact us for Stainless Steel and Millwork Design & Fabrication
            </h2>
            <a
              href={`tel:${
                phoneValidator(strapiSsContactPage.info_form_phone)[0]
              }`}
            >
              <div className="ContactDiv__icon">
                <i className="fas fa-phone" />
                <span>{strapiSsContactPage.info_form_phone}</span>
              </div>
            </a>
            <Link to="/our-company/contact">Get in Touch</Link>
          </div>
        </div>
      )
    }}
  />
)

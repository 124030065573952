import {useState, useEffect, useCallback} from "react"

const useWindowSize = () => {
  const windowGlobal = typeof window !== "undefined" ? window : false

  const [width, setWidth] = useState(windowGlobal.innerWidth)

  const handleWindowSizeChange = useCallback(() => {
    setWidth(windowGlobal.innerWidth)
  }, [windowGlobal.innerWidth]);

  useEffect(() => {
    windowGlobal &&
      windowGlobal.addEventListener("resize", handleWindowSizeChange)
  }, [windowGlobal, handleWindowSizeChange])

  useEffect(() => {
    return () => {
      windowGlobal &&
        windowGlobal.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [windowGlobal, handleWindowSizeChange])

  return { width }
}

export default useWindowSize

import styled from 'styled-components';

export const ShopNow = styled.div`
  max-width: 150px;
  margin: 1rem auto;
  border-radius: 25px;
  background: var(--base-color);
  border: 2px solid var(--base-color);
  padding: 9px;
  a {
    display: inline-block;
    background: var(--base-color);
    border-radius: 22px;
    color: white !important;
    text-decoration: none;
    text-align: center;
    font-size: 16px !important;
  }

  &:hover {
    color: var(--base-color);
    box-shadow: 4px 4px 4px var(--base-color);
    transform: scale(1.1);
    transition: ease 0.2s;
  }

  @media (min-width: 960px) {
    margin: 0 auto;
    border-radius: 25px;
    top: 40px;
    right: 80px;
  }
  @media (min-width: 1100px) {
    right: 100px;
  }
  @media (min-width: 1650px) {
    right: 180px;
  }
  @media (min-width: 1800px) {
    right: 200px;
    padding: 0.5rem 1.5rem;
  }
`;
import React from "react"

const ProcessFlowDesktop = (props) => {
  const { active, setActive, data } = props

  return (
    <div className="Tabs">
      <ul className="steps">
        {data.map((item) => {
          let className = `Tabs__Tab`
          if (item.id === active) {
            className = `${className} Tabs__Tab--active`
          }
          return (
            <li
              key={item.id}
              className={className}
              onClick={() => {
                setActive(item.id)
              }}
            >
              <p>{item.title}</p>
            </li>
          )
        })}
        <div className="Tabs__Underline" />
      </ul>
    </div>
  )
}

export default ProcessFlowDesktop
